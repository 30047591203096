// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-pelotonu-email-js": () => import("./../../../src/pages/apps/pelotonu-email.js" /* webpackChunkName: "component---src-pages-apps-pelotonu-email-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */)
}

